import React from 'react';
import { Grid, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'next-i18next';
import { IsVideoOrImg } from 'views/common/components/UI/GridsGallery/IsVideoOrImg';

const StyledGrid = styled(Grid)(({ theme }) => ({
  '& .instagramMediaBox': {
    height: theme.spacing(36.5),
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    '& span': {
      maxWidth: '100%',
    },
  },
  '& .loadMore': {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '500',
    color: theme.palette.secondary.main,
    textTransform: 'capitalize',
    margin: `${theme.spacing(2)} auto`,
    '& svg': {
      marginLeft: theme.spacing(1),
      width: theme.spacing(2.25),
      height: theme.spacing(2.25),
    },
  },
}));

const InstagramGallery = (props) => {
  const { slidesArray = [] } = props;
  const { t } = useTranslation('fe_er_common_grids_gallery');

  return (
    <StyledGrid container spacing={2}>
      {slidesArray.slice(0, 4).map((img) => (
        <Grid
          item
          xs={12}
          md={6}
          lg={3}
          key={`instagram-Gallery-${img.youtube_id || img.urls.large}`}>
          <NavigatorLink href={img.instagramLink}>
            <Box className="instagramMediaBox">
              <IsVideoOrImg slide={img} />
            </Box>
          </NavigatorLink>
        </Grid>
      ))}
      <Button className="loadMore">
        {t('fe_er_common_grids_gallery:load_more')}
        <ExpandMoreIcon />
      </Button>
    </StyledGrid>
  );
};

export { InstagramGallery };
