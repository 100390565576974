import React from 'react';
import Image from 'next/image';
import Youtube from 'react-lazyload-youtube';
import blur from 'public/imgs/1x1.webp';

const IsVideoOrImg = (props) => {
  const { slide } = props;
  if (slide) {
    if (slide.youtube_id) {
      return <Youtube videoId={slide.youtube_id} height="100%" width="100%" />;
    }
    return (
      <Image
        src={slide.urls.large}
        alt=""
        placeholder="blur"
        blurDataURL={blur.src}
        layout="fill"
        objectFit="cover"
        unoptimized
        objectPosition="50% 50%"
      />
    );
  }
  return false;
};

export { IsVideoOrImg };
