import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .HeroGallery-carousel ': {
    '& .arrow': {
      [theme.breakpoints.down('lg')]: {
        display: 'none ! important',
      },
    },
  },
  '& .react-multi-carousel-dot-list': {
    display: 'none',
  },
}));

export default StyledBox;
