import React from 'react';
import { ToursGallery } from 'views/common/components/UI/GridsGallery/ToursGallery';
import { InstagramGallery } from 'views/common/components/UI/GridsGallery/InstagramGallery';
import StyledBox from 'views/common/components/UI/GridsGallery/GridsGallery.styles';

const GridsGallery = (props) => {
  const {
    ssrRender,
    deviceType,
    slidesArray = [],
    galleryHeight = { LG: '300px', MD: '550px', SM: '220px' },
    variant,
    shouldResetAutoplay,
    className,
    sliderDots,
  } = props;
  return (
    <StyledBox className={className}>
      {variant == 'tours' && (
        <ToursGallery
          ssrRender={ssrRender}
          slidesArray={slidesArray}
          deviceType={deviceType}
          galleryHeight={galleryHeight}
          shouldResetAutoplay={shouldResetAutoplay}
          sliderDots={sliderDots}
        />
      )}
      {variant == 'instagram' && <InstagramGallery slidesArray={slidesArray} />}
    </StyledBox>
  );
};

export default GridsGallery;
